import { useCurrentProjectApiClient } from "@/components/common/project-provider/project-loading-context";
import { ElementIcon, ElementIconType } from "@/components/ui/icons";
import { useErrorHandlers } from "@/errors/components/error-handling-context";
import { openAlignmentWizard } from "@/modes/alignment-wizard/open-alignment-wizard";
import { selectActiveCadModel, setActiveCad } from "@/store/cad/cad-slice";
import { Features, selectHasFeature } from "@/store/features/features-slice";
import { AppDispatch, RootState } from "@/store/store";
import {
  useAppDispatch,
  useAppSelector,
  useAppStore,
} from "@/store/store-hooks";
import { selectCanReadCAD } from "@/store/subscriptions/subscriptions-selectors";
import { selectHasWritePermission } from "@/store/user-selectors";
import {
  cadImporterUIMessageToUserMessage,
  isCadImporterUIMessage,
} from "@/utils/cad-importer-ui-messages";
import {
  FaroText,
  removeIElement,
  selectIElement,
  useDialog,
} from "@faro-lotv/app-component-toolbox";
import { GUID, assert } from "@faro-lotv/foundation";
import {
  CadMessagesDescription,
  isIElementBimModelSection,
} from "@faro-lotv/ielement-types";
import {
  ProjectApi,
  createMutationDeleteElement,
} from "@faro-lotv/service-wires";
import { ListItemIcon, MenuItem } from "@mui/material";
import { useCallback, useRef, useState } from "react";
import { DeleteElementDialogContent } from "../../delete-element-dialog-content";
import { CreateDialogFn } from "../tree-context-menu/action-types";
import { ChangeCadCsDialog } from "../tree-context-menu/actions/change-cad-cs-dialog";
import { handleCadDownload } from "../tree-context-menu/actions/download-action";
import { TransformCadDialog } from "../tree-context-menu/actions/transform-cad-dialog";
import {
  ContextMenuBase,
  ContextMenuBaseHandle,
} from "../tree-context-menu/context-menu-base";

/** Props for ContextMenuCadModelTree component. */
type ContextMenuCadModelTreeProps = {
  /** id of the IElementModel3dStream of the CAD for the root node; undefined for all nodes but the root one  */
  idIElementModel3dStream?: GUID;

  /** cad model importer messages */
  modelMessages?: CadMessagesDescription;

  /** Callback called whenever the menu switches between open and close state (open = true when switching from close to open) */
  onToggle(open: boolean): void;
};

/**
 * @returns a context menu for the given item in the CAD Model Tree
 */
export function ContextMenuCadModelTree({
  idIElementModel3dStream,
  modelMessages,
  onToggle,
}: ContextMenuCadModelTreeProps): JSX.Element {
  const menuRef = useRef<ContextMenuBaseHandle>(null);

  const { getState } = useAppStore();
  const dispatch = useAppDispatch();
  const projectApi = useCurrentProjectApiClient();
  const { createDialog } = useDialog();
  const errorHandlers = useErrorHandlers();
  const activeCad = useAppSelector(selectActiveCadModel);
  const hasCadSupport = useAppSelector(selectCanReadCAD);
  const hasTransformCadSupport = useAppSelector(
    selectHasFeature(Features.TransformModel),
  );
  const hasChangeCadCsSupport = useAppSelector(
    selectHasFeature(Features.ChangeCadCs),
  );

  // If the user has permission to edit the project or not
  const hasWritePermission = useAppSelector(selectHasWritePermission);

  const hasAlignModelToCloudFeature = useAppSelector(
    selectHasFeature(Features.AlignModelToCloud),
  );

  const deleteCadCallback = useCallback(
    // eslint-disable-next-line require-await -- FIXME
    async (idIElementModel3dStream: GUID) => {
      deleteCad(
        idIElementModel3dStream,
        getState(),
        dispatch,
        projectApi,
        createDialog,
      ).catch((error) =>
        errorHandlers.handleErrorWithDialog({
          title: "Error: failed to delete the 3D Model",
          error,
        }),
      );
    },
    [projectApi, getState, dispatch, createDialog, errorHandlers],
  );

  /**
   * Handler to close the context menu when a MenuItem was clicked
   *
   * @param event the click event on the menu item
   */
  function handleClose(event: React.MouseEvent<HTMLElement>): void {
    // Prevent the label from receiving the event and selecting the element.
    event.stopPropagation();

    menuRef.current?.closeContextMenu();
  }

  const [transformCadDialogOpen, setTransformCadDialogOpen] = useState(false);

  const [changeCadCsDialogOpen, setChangeCadCsDialogOpen] = useState(false);

  const displayMenu =
    idIElementModel3dStream && (!!modelMessages?.length || hasWritePermission);
  return (
    <>
      {idIElementModel3dStream && transformCadDialogOpen && (
        <TransformCadDialog
          open={transformCadDialogOpen}
          idIElementModel3dStream={idIElementModel3dStream}
          onClose={() => {
            setTransformCadDialogOpen(false);
          }}
        />
      )}
      {idIElementModel3dStream && changeCadCsDialogOpen && (
        <ChangeCadCsDialog
          open={changeCadCsDialogOpen}
          idIElementModel3dStream={idIElementModel3dStream}
          onClose={() => {
            setChangeCadCsDialogOpen(false);
          }}
        />
      )}
      <ContextMenuBase
        ref={menuRef}
        visible={!!displayMenu}
        disabled={!idIElementModel3dStream}
        onToggle={onToggle}
      >
        {!!modelMessages?.length && (
          <MenuItem
            onClick={(ev) => {
              reportCadImportMessages(createDialog, modelMessages);
              handleClose(ev);
            }}
          >
            <ListItemIcon>
              <ElementIcon icon={ElementIconType.InfoIcon} />
            </ListItemIcon>

            <FaroText variant="labelL">Show import messages</FaroText>
          </MenuItem>
        )}
        {hasWritePermission
          ? [
              ...(hasCadSupport
                ? [
                    hasAlignModelToCloudFeature && (
                      <MenuItem
                        key="alignToCloud"
                        onClick={(ev) => {
                          if (activeCad) {
                            openAlignmentWizard({
                              dispatch,
                              elementIdToAlign: activeCad.id,
                            });
                          }
                          handleClose(ev);
                        }}
                      >
                        <ListItemIcon>
                          <ElementIcon icon={ElementIconType.AlignIcon} />
                        </ListItemIcon>

                        <FaroText variant="labelL">Align</FaroText>
                      </MenuItem>
                    ),

                    <MenuItem
                      key="downloadCad"
                      onClick={(ev) => {
                        if (idIElementModel3dStream) {
                          handleCadDownload(
                            idIElementModel3dStream,
                            getState(),
                          );
                        }
                        handleClose(ev);
                      }}
                    >
                      <ListItemIcon>
                        <ElementIcon icon={ElementIconType.DownloadIcon} />
                      </ListItemIcon>

                      <FaroText variant="labelL">Download 3D model</FaroText>
                    </MenuItem>,
                  ]
                : []),
              <MenuItem
                key="delete"
                onClick={(ev) => {
                  if (idIElementModel3dStream) {
                    deleteCadCallback(idIElementModel3dStream);
                  }
                  handleClose(ev);
                }}
              >
                <ListItemIcon>
                  <ElementIcon icon={ElementIconType.DeleteIcon} />
                </ListItemIcon>

                <FaroText variant="labelL">Delete</FaroText>
              </MenuItem>,
              ...(hasTransformCadSupport
                ? [
                    <MenuItem
                      key="transform"
                      onClick={(ev) => {
                        if (idIElementModel3dStream) {
                          setTransformCadDialogOpen(true);
                        }
                        handleClose(ev);
                      }}
                    >
                      <ListItemIcon>
                        <ElementIcon icon={ElementIconType.MoveElement} />
                      </ListItemIcon>

                      <FaroText variant="labelL">Rotate</FaroText>
                    </MenuItem>,
                  ]
                : []),
              ...(hasChangeCadCsSupport
                ? [
                    <MenuItem
                      key="changeCadCs"
                      onClick={(ev) => {
                        if (idIElementModel3dStream) {
                          setChangeCadCsDialogOpen(true);
                        }
                        handleClose(ev);
                      }}
                    >
                      <ListItemIcon>
                        <ElementIcon icon={ElementIconType.MoveElement} />
                      </ListItemIcon>
                      <FaroText variant="labelL">
                        Change Cad Coordinates System
                      </FaroText>
                    </MenuItem>,
                  ]
                : []),
            ]
          : null}
      </ContextMenuBase>
    </>
  );
}

/**
 * Ask user confirmation to delete a CAD and apply proper mutations if necessary.
 *
 * @param idIElementModel3dStream GUID of the IElementModel3dStream associated with the tree
 * @param state Current state of the application
 * @param dispatch Function to update the state.
 * @param projectApi Client to update the project.
 * @param createDialog An async function to create a dialog
 */
async function deleteCad(
  idIElementModel3dStream: GUID,
  state: RootState,
  dispatch: AppDispatch,
  projectApi: ProjectApi,
  createDialog: CreateDialogFn,
): Promise<void> {
  // Create a delete confirmation dialog
  const hasConfirmed = await createDialog({
    title: "Delete 3D Model?",
    confirmText: "Delete",
    content: <DeleteElementDialogContent name="the 3D Model" />,
    variant: "danger",
  });

  if (!hasConfirmed) return;

  const model3DStreamElement = selectIElement(idIElementModel3dStream)(state);
  assert(
    model3DStreamElement?.parentId,
    "Invalid 3D Model Element in deleteCad",
  );

  // get the IElementBimModelSection parent of the input iElement
  const bimModelSectionElement = selectIElement(model3DStreamElement.parentId)(
    state,
  );
  assert(
    bimModelSectionElement &&
      isIElementBimModelSection(bimModelSectionElement) &&
      bimModelSectionElement.parentId,
    "Invalid BimModelSection for deleted 3D Model",
  );

  // delete the parent of bimModelSectionElement, which is BimModel TimeSeries Element
  const deleteMutation = createMutationDeleteElement(
    bimModelSectionElement.parentId,
  );

  // Delete the element from the project.
  const resultMutation = await projectApi.applyMutations([deleteMutation]);

  if (resultMutation.length > 1 && resultMutation[0].status === "failure") {
    console.debug("Mutation to delete CAD has failed");
  }

  // Update store after removing the element.
  dispatch(removeIElement(bimModelSectionElement.id));

  // set current active CAD model as undefined. Then model tree UI will take care
  // of setting next active Model (first in alphabetical order) or keep dropdown empty
  // if removed CAD was a last one.
  dispatch(setActiveCad(undefined));
}

/**
 * Called by menu "Show import messages"
 *
 * @param createDialog An async function to create a dialog
 * @param modelMessages cad model import messages to report
 */
async function reportCadImportMessages(
  createDialog: CreateDialogFn,
  modelMessages: CadMessagesDescription,
): Promise<void> {
  const messageItems = modelMessages.map((message, index) => {
    const userMessage = cadImporterUIMessageToUserMessage(
      message.Text,
      isCadImporterUIMessage(message.Type) ? message.Type : undefined,
    );
    return (
      <p key={index}>
        <FaroText variant="bodyM">{userMessage}</FaroText>
      </p>
    );
  });
  await createDialog({
    title: "3D Model import messages",
    content: <div>{messageItems}</div>,
    showCancelButton: false,
    confirmText: "Ok",
  });
}
