import { MeasureLabel } from "@/components/r3f/renderers/measurements/measure-label";
import { useToggleUnitOfMeasure } from "@/hooks/use-unit-of-measure";
import { useViewOverlayRef } from "@/hooks/use-view-overlay-ref";
import { PointCloudAnalysis } from "@/store/point-cloud-analysis-tool-slice";
import { computeAnalysisReferencePlane } from "@/utils/colormap-analysis-utils";
import {
  getPickedPoint,
  useOverrideCursor,
} from "@faro-lotv/app-component-toolbox";
import {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";
import { Plane, Vector3 } from "three";
import { ToolCallback, ToolControlsRef } from "./tool-controls-interface";

type PointCloudAnalysisLabelToolProps = {
  /** True if this tool is the active one */
  isActive: boolean;

  /** The active analysis object */
  activeAnalysis: PointCloudAnalysis;
};

export const PointCloudAnalysisLabelTool = forwardRef<
  ToolControlsRef,
  PointCloudAnalysisLabelToolProps
>(function PointCloudAnalysisTool(
  { isActive, activeAnalysis }: PointCloudAnalysisLabelToolProps,
  ref,
): JSX.Element | null {
  const referencePlane = useMemo(() => {
    const plane = computeAnalysisReferencePlane(activeAnalysis);
    if (!plane) return;
    return new Plane().setFromNormalAndCoplanarPoint(plane.normal, plane.point);
  }, [activeAnalysis]);

  const [labelPosition, setLabelPosition] = useState<Vector3>();
  const [labelDistance, setLabelDistance] = useState<number>(0.0);

  const pointHovered = useCallback<ToolCallback>(
    (ev, iElementId) => {
      // only show preview if the hovered on the analysis's point cloud
      if (iElementId !== activeAnalysis.parentId) {
        setLabelPosition(undefined);
        return;
      }

      ev.stopPropagation();
      const p = getPickedPoint(ev);
      setLabelPosition(p);
      const distance = referencePlane ? referencePlane.distanceToPoint(p) : 0.0;
      setLabelDistance(distance);
    },
    [activeAnalysis.parentId, referencePlane],
  );

  useImperativeHandle(ref, () => ({
    pointHovered,
  }));

  useOverrideCursor("crosshair", isActive);

  const { unitOfMeasure } = useToggleUnitOfMeasure(false);
  const labelContainer = useViewOverlayRef();

  if (!isActive) return null;

  return (
    <MeasureLabel
      index={0}
      visible
      position={labelPosition}
      parentRef={labelContainer}
      distance={labelDistance}
      unitOfMeasure={unitOfMeasure}
      active
      transparent
      pointerEvents="none"
      placement="top"
    />
  );
});
