import {
  humanReadableDistance,
  neutral,
} from "@faro-lotv/app-component-toolbox";
import { SupportedUnitsOfMeasure } from "@faro-lotv/ielement-types";
import { Box, Paper, Typography } from "@mui/material";
import { Html, HtmlProps } from "@react-three/drei/web/Html";
import {
  MouseEventHandler,
  MutableRefObject,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Vector3 } from "three";
import { LabelsScreenPositionsComputer } from "../../utils/labels-screen-positions-computer";
import {
  INACTIVE_MEASURES_OPACITY,
  MEASURE_ANIMATION_LENGTH,
} from "./measure-constants";

type MeasureLabelProps = {
  /** True to show this label, will animate opacity on change */
  visible: boolean;

  /** Index of the segment used for the position computation */
  index: number;

  /** The 3D position that the label should have in the scene */
  position: Vector3 | undefined;

  /** The parent that the label should have in the html DOM */
  parentRef: MutableRefObject<HTMLElement>;

  /** The the number to show in the label representing the length. */
  distance: number;

  /** Optional symbol that shows befor the measurement (Eg. X, Y, Z) */
  symbol?: string;

  /** Optional units symbol.*/
  unitOfMeasure: SupportedUnitsOfMeasure;

  /** Support class to optimize the actual position of the labels so they do not overlap */
  labelsScreenPositionsComputer?: LabelsScreenPositionsComputer;

  /** True if this label is active (selected) */
  active: boolean;

  /** Callback when this label is clicked */
  onClick?: MouseEventHandler<HTMLDivElement>;

  /** True if this label should be transparent at INACTIVE_MEASURES_OPACITY, false if it should be opaque. */
  transparent: boolean;

  /** The pointerEvents allowed on the label */
  pointerEvents: HtmlProps["pointerEvents"];

  /** Optional placement of the label related to the position */
  placement?: "top" | "bottom";
};

/**
 * @returns a Html label that displays a measurement and moves in the 3D scene along with a given 3D position.
 */
export function MeasureLabel({
  index,
  visible,
  position,
  parentRef,
  distance: distanceInMeters,
  symbol = "",
  unitOfMeasure,
  labelsScreenPositionsComputer,
  active,
  onClick,
  transparent,
  pointerEvents,
  placement,
}: MeasureLabelProps): JSX.Element | null {
  const distance = useMemo(
    () => humanReadableDistance(distanceInMeters, unitOfMeasure),
    [distanceInMeters, unitOfMeasure],
  );

  // Taking a ref to the HTML label to know at runtime any moment its width and height,
  // needed for the label collision resolution algorithm.
  const ref = useRef<HTMLDivElement>(null);

  // Whenever something changes, the screen size of the measure label is updated to
  // the labels position computer.
  useEffect(() => {
    if (ref.current) {
      labelsScreenPositionsComputer?.setLabelSize(
        index,
        ref.current.clientWidth,
        ref.current.clientHeight,
      );
    }
  });

  const [opacity, setOpacity] = useState(0);

  useEffect(() => {
    if (!visible) setOpacity(0);
    else if (transparent) setOpacity(INACTIVE_MEASURES_OPACITY);
    else setOpacity(1);
  }, [transparent, visible]);

  const transform = useMemo(() => {
    switch (placement) {
      case "top":
        return "translateY(-80%)";
      case "bottom":
        return "translateY(80%)";
    }
    return "";
  }, [placement]);

  if (!position) {
    return null;
  }
  return (
    <Html
      position={position}
      portal={parentRef}
      style={{
        pointerEvents: visible ? pointerEvents : "none",
        display: "block",
        transform,
      }}
      calculatePosition={
        labelsScreenPositionsComputer
          ? () => labelsScreenPositionsComputer.position(index)
          : undefined
      }
      zIndexRange={[0, 0]}
      ref={ref}
    >
      {/* Main Body */}
      <Paper
        elevation={0}
        onClick={onClick}
        sx={{
          padding: "5px",
          userSelect: active ? "text" : "none",
          cursor: active ? "text" : "default",
          opacity,
          transition: `opacity ${MEASURE_ANIMATION_LENGTH}s linear`,
          transform: "translate(-50%, -50%)",
          pointerEvents: visible ? pointerEvents : "none",
          backgroundColor: neutral[999],
          outline: `${neutral[0]}33 solid 1px`,
        }}
      >
        <Typography
          noWrap
          sx={{ fontSize: "0.75em", fontWeight: "inherit", color: neutral[0] }}
        >
          {symbol && (
            <Box
              component="span"
              sx={{ opacity: 0.5 }}
            >{`${symbol}\u00A0`}</Box>
          )}
          {distance}
        </Typography>
      </Paper>
    </Html>
  );
}
