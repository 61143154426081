import { selectDefaultModeFor } from "@/modes";
import { ModeNames } from "@/modes/mode";
import { useIsCurrentAreaLoading } from "@/modes/mode-data-context";
import { changeMode } from "@/store/mode-slice";
import { selectActiveArea } from "@/store/selections-selectors";
import { setActiveArea, setActiveElement } from "@/store/selections-slice";
import { RootState } from "@/store/store";
import { useAppDispatch, useAppSelector } from "@/store/store-hooks";
import { CircularProgress, Dropdown, FaroText } from "@faro-lotv/flat-ui";
import { GUID } from "@faro-lotv/foundation";
import {
  IElement,
  IElementAreaSection,
  isIElementAreaSection,
} from "@faro-lotv/ielement-types";
import {
  selectChildrenDepthFirst,
  selectIElement,
  selectRootIElement,
} from "@faro-lotv/project-source";
import { Stack } from "@mui/material";
import { createSelector } from "@reduxjs/toolkit";
import { isEqual } from "lodash";
import { useCallback } from "react";
import { selectTreeNodeDisabledReason } from "./tree/capture-tree/capture-tree-node-selectors";

/**
 * @returns the content of the project overview panel. It will contain the area selector and the list of elements in the area
 */
export function AreaNavigationPanel(): JSX.Element {
  const dispatch = useAppDispatch();

  const activeArea = useAppSelector(selectActiveArea);
  const isLoading = useIsCurrentAreaLoading();

  const areas = useAppSelector(selectAreaSections);
  const areaOptions = useAppSelector(selectAreaOptions(areas), isEqual);

  const changeActiveArea = useCallback(
    (areaId: GUID) => {
      dispatch(setActiveArea(areaId));

      // TODO: Define what is the supposed behavior when changing the area (keep same activeElement if possible or not)
      // Currently to be safe, the active element is reset to the area
      dispatch(setActiveElement(areaId));

      // Find the best mode to use for the area
      const modeToUse = areaOptions.find((area) => area.key === areaId)?.mode;

      if (modeToUse?.targetMode) {
        dispatch(changeMode(modeToUse.targetMode));
      }
    },
    [areaOptions, dispatch],
  );

  return (
    <Stack gap={2}>
      <Dropdown
        label="Areas"
        options={areaOptions}
        value={activeArea?.id}
        onChange={(ev) => changeActiveArea(ev.target.value)}
      />
      {isLoading ? <CircularProgress size={20} /> : <AreaDataList />}
    </Stack>
  );
}

function AreaDataList(): JSX.Element {
  return (
    <Stack gap={2}>
      <FaroText variant="labelM">Datasets</FaroText>
      <FaroText variant="labelM">Pano captures</FaroText>
    </Stack>
  );
}

const selectAreaSections = createSelector(
  [
    (state: RootState) => state,
    (state, refElementId?: GUID) =>
      refElementId
        ? selectIElement(refElementId)(state)
        : selectRootIElement(state),
  ],
  (state, refElement) => {
    return selectChildrenDepthFirst(refElement, isIElementAreaSection)(state);
  },
);

type AreaOption = {
  /** The unique key of the option */
  key: GUID;
  /** The value of the option */
  value: GUID;
  /** The label to display in the dropdown */
  label: string;
  /** True if the option is disabled */
  isDisabled: boolean;
  /** The mode to use when selecting this area */
  mode?: { targetMode: ModeNames; element: IElement };
};

function selectAreaOptions(areaOptions: IElementAreaSection[]) {
  return (state: RootState): AreaOption[] => {
    return areaOptions.map((area) => {
      const disabledReason = selectTreeNodeDisabledReason(area.id)(state);
      const modeToUse = selectDefaultModeFor(area)(state);

      return {
        key: area.id,
        value: area.id,
        label: area.name,
        isDisabled: !!disabledReason,
        mode: modeToUse,
      };
    });
  };
}
